import React from 'react';

const IkeonoLogo = () => {
  return (
    <svg
      width="84.9424844px"
      height="14px"
      viewBox="0 0 84.9424844 14"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Ikeono-logo-black" fill="#000000" fillRule="nonzero">
          <g id="Group" transform="translate(0, -0)">
            <path
              d="M6.88940108,9.51030187e-08 C4.10333747,-0.000461231358 1.59135109,1.6774679 0.524850968,4.25132262 C-0.541649154,6.82517734 0.0473823259,9.78804144 2.01726366,11.7582491 C3.987145,13.7284567 6.9499115,14.3179789 9.52394283,13.2519051 C12.0979742,12.1858313 13.7763194,9.67412291 13.7763194,6.88805927 C13.7760045,3.08446516 10.6929951,0.000945013049 6.88940108,9.51030187e-08 Z M4.68911225,10.7148221 L3.34869002,10.7148221 L3.34869002,3.0612964 L4.68911225,3.0612964 L4.68911225,10.7148221 Z"
              id="Path_9388"
            ></path>
          </g>
          <g id="Ikeono" transform="translate(16.5909, 0.0804)">
            <polygon
              id="Path"
              points="0 0 4.24086778 0 4.24086778 13.6956928 0 13.6956928"
            ></polygon>
            <polygon
              id="Path-2"
              points="7.02495326 0 10.9118925 0 10.9118925 7.06145838 13.7521617 3.77343117 18.4322316 3.77343117 14.8735532 7.24968788 18.6381433 13.6951224 14.3502181 13.6951224 12.341296 9.71549439 10.9118925 11.1169486 10.9118925 13.6951224 7.02495326 13.6951224"
            ></polygon>
            <path
              d="M30.1475219,9.24891338 C30.1475219,7.940243 29.932579,6.87798215 29.5026933,6.06213083 C29.0859372,5.25980327 28.4300403,4.60689626 27.6258169,4.1938104 C26.8031209,3.76430489 25.6819195,3.54964721 24.2622127,3.54983721 C22.5130093,3.54983721 21.1411204,4.02915712 20.1465461,4.98779667 C19.1519718,5.94643623 18.6553458,7.20129959 18.6569662,8.75238676 C18.6323187,9.75154454 18.8892786,10.7374802 19.3984764,11.5975042 C19.8400634,12.3543002 20.4906742,12.9675776 21.2722156,13.3637244 C22.0260842,13.7348598 23.0599205,13.9201422 24.3737245,13.9195732 C25.8871657,13.9195732 27.048865,13.7033931 27.8588223,13.2710357 C28.7388564,12.7662878 29.4579248,12.0225978 29.9327692,11.1260749 L26.187002,10.7798467 C26.005823,11.0282488 25.7802366,11.2409749 25.5216392,11.4072784 C25.1970993,11.5915273 24.8301955,11.688143 24.4570018,11.6876262 C23.9040025,11.7050581 23.373607,11.467668 23.0181868,11.0436532 C22.7200623,10.6489541 22.547734,10.1736102 22.5236566,9.67955967 L30.1475219,9.67955967 L30.1475219,9.24891338 Z M22.5327829,7.85601505 C22.5554891,7.37925646 22.7072965,6.91762407 22.971985,6.52044115 C23.289039,6.11232162 23.7614677,5.85435296 24.2762115,5.80827102 C24.7909554,5.76218909 25.3016932,5.93214076 25.6861975,6.27745397 C26.0065679,6.59516256 26.2043039,7.12134959 26.2794056,7.85601505 L22.5327829,7.85601505 Z"
              id="Shape"
            ></path>
            <path
              d="M41.6015725,5.27784123 C40.5866542,4.12602876 39.0851911,3.55012253 37.0971834,3.55012253 C35.359768,3.55012253 33.9802739,4.04056496 32.958701,5.02144983 C31.9398702,5.99450668 31.3826637,7.35475831 31.4260566,8.76293902 C31.3802081,10.2884079 32.0511193,11.7473785 33.2390489,12.7054916 C34.2224055,13.5148784 35.5116825,13.9195719 37.10688,13.9195719 C38.8941097,13.9195719 40.2936626,13.4322666 41.3055388,12.457656 C42.3198204,11.4785517 42.8715812,10.1154206 42.8239234,8.70647017 C42.8610485,7.45071486 42.4247394,6.22689121 41.6015725,5.27784123 L41.6015725,5.27784123 Z M38.4761071,10.725089 C38.1402286,11.1234849 37.6450944,11.3525876 37.1240088,11.3507274 C36.6029233,11.3488444 36.1094476,11.1161908 35.7764397,10.7153923 C35.4186135,10.2915908 35.2397004,9.63754076 35.2397004,8.75324235 C35.2397004,7.86913407 35.4196592,7.23200572 35.7815732,6.80535218 C36.1158307,6.39164505 36.6224526,6.15554431 37.1542226,6.16565706 C37.6668358,6.16108835 38.1527389,6.39387961 38.4704032,6.7962259 C38.826328,7.21717552 39.0039102,7.85715583 39.0031521,8.71616684 C39.0031521,9.63735063 38.8270886,10.3069914 38.4749663,10.725089 L38.4761071,10.725089 Z"
              id="Shape-2"
            ></path>
            <path
              d="M44.6563092,3.77400156 L48.1970202,3.77400156 L48.1970202,5.38963814 C48.6300636,4.81273524 49.1772012,4.33112601 49.8043861,3.9747797 C50.4204692,3.67516893 51.1000276,3.52945142 51.7847886,3.55012253 C52.8369726,3.55012253 53.6606192,3.86298279 54.2557287,4.48870329 C54.8508381,5.11442379 55.1483929,6.08133404 55.1483929,7.38943403 L55.1483929,13.6956928 L51.3267635,13.6956928 L51.3267635,8.23960396 C51.3267635,7.61673542 51.2116393,7.17601219 50.9813909,6.91743429 C50.7309276,6.65047983 50.3748741,6.50849723 50.0094422,6.52985263 C49.5741453,6.51785503 49.1586713,6.71179923 48.8883358,7.05318769 C48.6031396,7.40245799 48.4599711,8.02846369 48.4588303,8.93120479 L48.4588303,13.6956928 L44.6563092,13.6956928 L44.6563092,3.77400156 Z"
              id="Path-3"
            ></path>
            <path
              d="M67.1254933,5.27784123 C66.1096243,4.12602876 64.6086366,3.55012253 62.6225302,3.55012253 C60.8843543,3.55012253 59.5044799,4.04056496 58.482907,5.02144983 C57.4646655,5.9948442 56.9078305,7.35494801 56.9511181,8.76293902 C56.9049942,10.2882124 57.5754669,11.7471475 58.7629697,12.7054916 C59.7472769,13.5148784 61.036554,13.9195719 62.6308008,13.9195719 C64.4180305,13.9195719 65.8179637,13.4322666 66.8306004,12.457656 C67.8448076,11.4785224 68.3964678,10.1153836 68.3486998,8.70647017 C68.3855887,7.45060137 67.9489693,6.226764 67.1254933,5.27784123 L67.1254933,5.27784123 Z M64.0000279,10.725089 C63.6641495,11.1234849 63.1690152,11.3525876 62.6479296,11.3507274 C62.1268441,11.3488444 61.6333684,11.1161908 61.3003605,10.7153923 C60.9415836,10.2915908 60.7623854,9.63754076 60.762765,8.75324235 C60.762765,7.86913407 60.94358,7.23200572 61.3046384,6.80535218 C61.6386477,6.39129409 62.1454051,6.15502487 62.6772878,6.16537186 C63.1902714,6.16057921 63.6766155,6.39337999 63.9946092,6.7959407 C64.3495834,7.21689032 64.5271656,7.85687063 64.5273557,8.71588165 C64.5273557,9.63706543 64.3512946,10.3067062 63.9991723,10.7248038 L64.0000279,10.725089 Z"
              id="Shape-3"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default IkeonoLogo;
