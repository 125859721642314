import React from 'react';
import { CheckMarkIcon } from '../../../assets/icons/CheckMarkIcon';
import Copy from '../../../copy/Copy';

const PrimaryMessageCard = ({ children, hidden }) => {
  if (hidden) return null;
  return (
    <div
      style={{
        width: '80%',
        borderRadius: 30,
        borderTopRightRadius: 30,
        borderTopLeftRadius: 30,
        borderBottomLeftRadius: 30,
        borderBottomRightRadius: 0,
        backgroundColor: '#2E6AD9',
        boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: 'auto',
      }}
    >
      <div
        style={{
          width: '100%',
          height: 25,
          backgroundColor: '#81a6e9',
          borderTopLeftRadius: 24,
          borderTopRightRadius: 24,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <p
          style={{
            fontSize: 10,
            color: 'white',
            padding: '0px 15px',
          }}
        >
          <Copy>textMessageSent</Copy>
        </p>
        <div style={{ marginLeft: -10 }}>
          <CheckMarkIcon width={13} height={13} color="#FFFFFF" />
        </div>
      </div>
      <div style={{ width: '85%' }}>
        <p
          style={{
            fontSize: window.screen.width < 768 ? '12px' : '15px',
            fontWeight: 'light',
            color: 'white',
          }}
        >
          {children}
        </p>
      </div>
    </div>
  );
};

export default PrimaryMessageCard;
