import en from './en';
import fr from './fr';

export const useCopy = () => {
  let pageLanguage = null;
  try {
    pageLanguage = document
      .getElementsByTagName('html')[0]
      .getAttribute('lang');
  } catch {}
  const userLanguage = navigator.language;

  if (
    pageLanguage == 'fc' ||
    userLanguage?.includes('fr') ||
    userLanguage?.includes('fc')
  ) {
    return fr;
  }
  return en;
};
