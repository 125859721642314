import React from 'react';
import { MessageIcon } from '../../../assets/icons/MessageIcon';
import './style.css';
import CloseSvg from './CloseSvg';
import Copy from '../../../copy/Copy';

function WidgetHeader({ startColor, endColor, onClose }) {
  return (
    <div
      className="widget__header"
      style={{
        background: `linear-gradient(90deg, ${startColor} 11%, ${endColor} 100%)`,
      }}
    >
      <div
        className="widget__header_message_icon"
        style={{
          marginTop: '6px',
          marginRight: '8px',
        }}
      >
        <MessageIcon />
      </div>
      <div>
        <Copy>title</Copy>
      </div>
      <button type="button" onClick={onClose}>
        <CloseSvg />
      </button>
    </div>
  );
}

export default WidgetHeader;
