import React from 'react';

const MessageCard = ({ children, hidden = false, direction = 'inbound' }) => {
  if (hidden) return null;
  const directionStyle = {
    borderBottomLeftRadius: 0,
    backgroundColor: '#E3E3E3',
  };

  if (direction === 'outbound') {
    directionStyle.backgroundColor = 'white';
    directionStyle.borderBottomLeftRadius = 30;
    directionStyle.borderBottomRightRadius = 0;
    directionStyle.marginLeft = 'auto';
  }

  return (
    <div
      style={{
        padding: '12px',
        width: '80%',
        borderRadius: '15px',
        boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
        fontSize: '15px',
        fontWeight: 'light',
        ...directionStyle,
      }}
    >
      {children}
    </div>
  );
};

export default MessageCard;
