import React from 'react';
import WidgetForm from '../common/WidgetForm';
import * as config from '../../lib/config';
import './style.scss';
import GoogleReviewMessageCard from '../common/Cards/GoogleMessageCard';
import MessageCard from '../common/Cards/MessageCard';
import PrimaryMessageCard from '../common/Cards/PrimaryMessageCard';
import ResendMessageButton from '../common/Buttons/ResendMessageButton';
import SendMessageButton from '../common/Buttons/SendMessageButton';
import { postSendMessageEvent } from '../../lib/googleAnalytics';
import Copy from '../../copy/Copy';
import { useCopy } from '../../copy';

class SendStatus {
  static Unsent = 0;
  static Sending = 1;
  static Success = 2;
  static Failed = 3;
}

const LocationChat = ({
  selectedShopToken,
  defaultMessage,
  themeStartColor,
  themeEndColor,
  hidden,
  googleRating,
  googleReviewsEnabled,
  googleAnalyticsEnabled,
}) => {
  const copy = useCopy();
  const [message, setMessage] = React.useState(defaultMessage || '');
  const [name, setName] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [sendStatus, setSendStatus] = React.useState(SendStatus.Unsent);
  const [error, setError] = React.useState(null);

  const sendMessage = (data) => {
    setSendStatus(SendStatus.Sending);

    const onSuccess = () => {
      setSendStatus(SendStatus.Success);
      if (!googleAnalyticsEnabled) return;
      postSendMessageEvent(
        data.customer_mobile_number,
        data.customer_name,
        data.origin,
        data.body,
        selectedShopToken
      );
    };

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    };

    setError(null);
    fetch(config.ApiEndpoint, requestOptions)
      .then((response) => {
        if (!response.ok) {
          response
            .json()
            .then((data) => {
              setSendStatus(SendStatus.Failed);
            })
            .catch((error) => {
              console.error(error);
              setSendStatus(SendStatus.Failed);
              setError(copy.error);
            });
        } else {
          onSuccess();
        }
      })
      .catch((error) => {
        console.error(error);
        setError(copy.error);
      });
  };

  const fetchParentOrigin = () => {
    return new Promise((resolve) => {
      window.parent.postMessage(
        {
          type: 'CURRENT_ORIGIN',
        },
        '*'
      );
      window.onmessage = function (event) {
        if (event.data.type === 'CURRENT_ORIGIN') {
          resolve(event.data);
        }
      };
    });
  };

  const handleSubmit = () => {
    setSendStatus(SendStatus.Sending);

    fetchParentOrigin().then(({ origin, windowWidth }) => {
      const data = {
        customer_name: name,
        customer_mobile_number: phone.replace(/[^\+0-9]/g, ''),
        body: message,
        token: selectedShopToken,
        origin: origin || document.referrer,
        window_width: windowWidth,
      };
      sendMessage(data);
    });
  };

  if (hidden) return null;

  return (
    <>
      <div className="message-list">
        <MessageCard>
          <Copy>messageCta</Copy>
        </MessageCard>
        <GoogleReviewMessageCard
          hidden={!googleReviewsEnabled}
          rating={googleRating}
        />
        <MessageCard
          direction="outbound"
          hidden={sendStatus === SendStatus.Success}
        >
          <WidgetForm
            defaultMessage={defaultMessage}
            onFormChange={({ isValid, name, phone, message }) => {
              setName(name);
              setPhone(phone);
              setMessage(message);
            }}
          />
        </MessageCard>
        <PrimaryMessageCard hidden={sendStatus !== SendStatus.Success}>
          {message}
        </PrimaryMessageCard>
        <MessageCard hidden={sendStatus !== SendStatus.Success}>
          <Copy>thankYouMessage</Copy>
        </MessageCard>
      </div>
      <div className="button-container">
        <SendMessageButton
          hidden={sendStatus === SendStatus.Success}
          themeStartColor={themeStartColor}
          themeEndColor={themeEndColor}
          onClick={handleSubmit}
          showLoader={sendStatus === SendStatus.Sending}
          disabled={
            !name || !message || !phone || sendStatus === SendStatus.Sending
          }
        />
        <ResendMessageButton
          hidden={sendStatus !== SendStatus.Success}
          onClick={() => {
            setSendStatus(SendStatus.Unsent);
            setMessage('');
            setPhone('');
            setName('');
          }}
        />
        <div className="error">{error}</div>
      </div>
    </>
  );
};

export default LocationChat;
