import React from 'react';
import WelcomeMessagePopup from './WelcomeMessagePopup';
import { WidgetButtonPicker } from '../OpenWidgetButton';
import WidgetNavigationManager from './WidgetNavigationManager';

const WidgetApp = ({
  isChatPopupVisible,
  welcomeMessage,
  isCTAWidget,
  themeStartColor,
  themeEndColor,
  toggleWidget,
  dockLeft,
  formOpened,
  hideButton,
  selectedShopToken,
  widgetFormRef,
  widgetCircleRef,
  widgetButtonRef,
  defaultMessage,
  bottomOffset,
  locations,
  greeterImageUrl,
  googleAnalyticsEnabled,
  googleReviewsEnabled,
  googleRating,
  showGreeterMessage,
  hideBranding,
}) => {
  return (
    <>
      <WelcomeMessagePopup
        hidden={
          !isChatPopupVisible ||
          !welcomeMessage?.trim() ||
          hideButton ||
          !showGreeterMessage
        }
        message={welcomeMessage}
        bottomOffset={isCTAWidget ? 90 : 100}
        dockLeft={dockLeft}
        greeterImageUrl={greeterImageUrl}
      />
      <WidgetButtonPicker
        buttonType={isCTAWidget ? 'cta' : 'rounded'}
        themeStartColor={themeStartColor}
        themeEndColor={themeEndColor}
        dockLeft={dockLeft}
        onClick={toggleWidget}
        buttonRef={isCTAWidget ? widgetButtonRef : widgetCircleRef}
        formOpened={formOpened}
        hidden={hideButton}
      />
      <WidgetNavigationManager
        selectedShopToken={selectedShopToken}
        widgetFormRef={widgetFormRef}
        dockLeft={dockLeft}
        toggleWidget={toggleWidget}
        themeStartColor={themeStartColor}
        themeEndColor={themeEndColor}
        defaultMessage={defaultMessage}
        locations={locations}
        bottomOffset={bottomOffset}
        googleAnalyticsEnabled={googleAnalyticsEnabled}
        googleReviewsEnabled={googleReviewsEnabled}
        googleRating={googleRating}
        hideBranding={hideBranding}
      />
    </>
  );
};

export default WidgetApp;
