import React from 'react';
import IkeonoLogo from './IkeonoLogo';

function WidgetFooter({ hidden }) {
  const isMobile = window.screen.width < 768;

  return (
    <div
      style={{
        width: '100%',
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        borderBottomLeftRadius: '24px',
        borderBottomRightRadius: '24px',
        backgroundColor: 'white',
        height: hidden ? '' : '40px',
      }}
    >
      <div
        style={{
          backgroundColor: hidden ? 'var(--ik-widget-bg-color-10)' : 'white',
          width: '100%',
          height: '100%',
          borderBottomLeftRadius: '24px',
          borderBottomRightRadius: '24px',
          minHeight: '15px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            display: hidden ? 'none' : 'flex',
            gap: '3px',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: isMobile ? '12px' : '13px',
            padding: '0px',
            margin: '4px',
          }}
        >
          <a
            href={
              isMobile
                ? ''
                : 'https://www.ikeono.com/?utm_medium=product&utm_source=webchat&utm_term=prospecting'
            }
            target="_blank"
            style={{
              textDecorationLine: isMobile ? 'none' : 'underline',
              color: isMobile ? 'black' : 'blue',
              pointerEvents: isMobile ? 'none' : 'all',
            }}
          >
            Start texting now
          </a>{' '}
          with
          <IkeonoLogo />
        </div>
      </div>
    </div>
  );
}

export default WidgetFooter;
