/* eslint-disable prettier/prettier */

const en = {
  title: 'Text us for a quick reply!',
  messageCta: 'Enter your information, and we will text you shortly.',
  googleMessage: 'See why customers love us.',
  formName: 'Name',
  formNamePlaceHolder: 'First and Last Name',
  formPhone: 'Phone Number',
  formMessage: 'Message',
  formMessagePlaceholder: 'How can we help you?',
  disclaimer:
    'By texting us, you agree to receive text messages at the number provided. Standard message/data rates apply.',
  terms: 'User is subject to terms.',
  enterInfoMessage: 'Enter your information, and we will text you shortly.',
  thankYouMessage:
    "Thanks for reaching out! We've received your message and will text you shortly.",
  sendNewMessage: 'Send new message',
  textMessageSent: 'Text message sent.',
  send: 'Send',
  textUs: 'Text us',
  search: 'Search',
  error: 'There was an error. Please try again.',
};

export default en;
