import React, { Component } from 'react';
import DefaultWidgetManager from './components/WidgetManager/DefaultWidgetManager';

const App = () => {
  let hideButton = undefined;
  if (process.env.NODE_ENV === 'development') {
    hideButton = false;
  }

  return <DefaultWidgetManager hideButton={hideButton} />;
};

export default App;
