import React from 'react';
import Disclaimer from '../Disclaimer';
import { hexToRgba } from '../../../lib/color';
import Loader from '../Loader';
import Copy from '../../../copy/Copy';

const SendMessageButton = ({
  hidden,
  onClick,
  disabled,
  showLoader,
  themeStartColor,
  themeEndColor,
}) => {
  if (hidden) return null;
  const background = !disabled
    ? `linear-gradient(90deg, ${hexToRgba(themeStartColor)} 11%, ${hexToRgba(
        themeEndColor
      )} 100%)`
    : '#E3E3E3';

  return (
    <div
      style={{
        fontSize: '10px',
        color: '#333',
        marginTop: window.screen.width < 768 ? '-10px' : '10px',
        padding: '0px 25px 15px 25px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Disclaimer />
      <button
        disabled={disabled}
        type="button"
        onClick={onClick}
        style={{
          width: '100%',
          height: '40px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          border: 'none',
          marginTop: 20,
          background,
          borderRadius: 10,
          cursor: disabled ? null : 'pointer',
        }}
      >
        {showLoader ? (
          <Loader />
        ) : (
          <p
            style={{
              fontSize: window.screen.width < 768 ? '12px' : '15px',
              color: disabled ? 'white' : 'var(--ik-widget-text-color)',
            }}
          >
            <Copy>send</Copy>
          </p>
        )}
      </button>
    </div>
  );
};

export default SendMessageButton;
