import React from 'react';
import Copy from '../../copy/Copy';

const Disclaimer = () => {
  return (
    <>
      <p
        style={{
          textAlign: 'center',
          fontFamily: 'Inter',
          fontWeight: '400',
          color: 'gray',
          marginTop: '0px',
        }}
      >
        <Copy>disclaimer</Copy>
      </p>
      <a
        target="_blank"
        href="https://ikeono.com/terms-of-use"
        style={{ marginTop: -10 }}
      >
        <Copy>terms</Copy>
      </a>
    </>
  );
};

export default Disclaimer;
