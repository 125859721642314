import React from 'react';
import WidgetHeader from '../common/WidgetHeader';
import CloseButton from '../common/Buttons/CloseButton';
import WidgetFooter from '../WidgetFooter';
import { hexToRgba } from '../../lib/color';

const WidgetContainer = ({
  dockLeft,
  themeStartColor,
  themeEndColor,
  widgetFormRef,
  toggleWidget,
  children,
  hideBranding,
}) => {
  return (
    <div ref={widgetFormRef} className="widget widget-hide">
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        style={{
          height: '100%',
          borderRadius: '24px',
          display: 'flex',
          flexDirection: 'column',
          boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
          background: 'white',
        }}
      >
        <WidgetHeader
          onClose={() => toggleWidget()}
          startColor={hexToRgba(themeStartColor)}
          endColor={hexToRgba(themeEndColor)}
        />
        <div className="widget-container">
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'var(--ik-widget-bg-color-10)',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {children}{' '}
          </div>
        </div>

        <WidgetFooter hidden={hideBranding} />
      </div>
      <CloseButton
        dockLeft={dockLeft}
        onClick={(e) => {
          e.stopPropagation();
          toggleWidget();
        }}
        startColor={hexToRgba(themeStartColor)}
        endColor={hexToRgba(themeEndColor)}
      />
    </div>
  );
};

export default WidgetContainer;
