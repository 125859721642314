/* eslint-disable prettier/prettier */

const fr = {
  title: 'Envoyez-nous un texto!',
  messageCta:
    'Entrez vos informations, et nous vous enverrons un message sous peu.',
  googleMessage: 'Découvrez pourquoi nos clients nous adorent.',
  formName: 'Nom',
  formNamePlaceHolder: 'Prénom et nom de famille',
  formPhone: 'Numéro de téléphoner',
  formMessage: 'Message',
  formMessagePlaceholder: 'Comment pouvons-nous vous aider?',
  disclaimer:
    "En nous envoyant un message texte, vous acceptez de recevoir des messages textes, y compris des offres marketing et promotionnelles, au numéro fourni. Des frais standards de message/données peuvent s’appliquer. L'utilisateur est soumis aux conditions.",
  terms: 'L’utilisateur est soumis aux conditions.',
  enterInfoMessage:
    'Entrez vos informations, et nous vous enverrons un message sous peu.',
  thankYouMessage:
    'Merci de nous avoir contactés ! Nous avons reçu votre message et vous enverrons un texto sous peu.',
  sendNewMessage: 'Envoyer une nouvelle message',
  textMessageSent: 'Message texte envoyé.',
  send: 'Envoyer',
  textUs: 'Textez',
  search: 'Recherche',
  error: 'Une erreur est survenue. Veuillez réessayer.',
};
export default fr;
