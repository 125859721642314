import React from 'react';
import ShimDataListener from './ShimEventListener';
import LocalEventHandler from './LocalEventHandler';
import WidgetApp from '../Widget';
import { hexToRgba } from '../../lib/color';

const setCssVar = (name, value) => {
  document.documentElement.style.setProperty(name, value);
};

const DefaultWidgetManager = (props) => {
  const [dockLeft, setDockLeft] = React.useState(false);
  const [minimized, setMinimized] = React.useState(true);
  const [bottomOffset, setBottomOffset] = React.useState(0);
  const [locations, setLocations] = React.useState(props.locations);
  const [selectedShopToken, setSelectedShopToken] = React.useState();
  const [hideButton, setHideButton] = React.useState(true);
  const [formOpenOnLoad, setFormOpenOnLoad] = React.useState(false);
  const [isChatPopupVisible, setIsChatPopupVisible] = React.useState(false);
  const [isCTAWidget, setIsCTAWidget] = React.useState(true);
  const [welcomeMessage, setWelcomeMessage] = React.useState();
  const [themeStartColor, setThemeStartColor] = React.useState('#6e2ebe');
  const [themeEndColor, setThemeEndColor] = React.useState('#2e6ad9');
  const [defaultMessage, setDefaultMessage] = React.useState('');
  const [greeterImageUrl, setGreeterImageUrl] = React.useState();
  const [googleRating, setGoogleRating] = React.useState();
  const [googleReviewsEnabled, setGoogleReviewsEnabled] = React.useState();
  const [googleAnalyticsEnabled, setGoogleAnalyticsEnabled] = React.useState();
  const [showGreeterMessage, setShowGreeterMessage] = React.useState();
  const [hideBranding, setHideBranding] = React.useState();

  // Refs
  const [widgetFormRef] = React.useState(React.createRef());
  const [widgetButtonRef] = React.useState(React.createRef());
  const [widgetCircleRef] = React.useState(React.createRef());

  React.useEffect(() => {
    let hideButton = true;
    if (props.hideButton !== undefined) {
      hideButton = false;
      setHideButton(hideButton);
    }
  }, []);

  const onInit = (data) => {
    const {
      shopToken,
      formOpened,
      dockLeft,
      bottomOffset,
      locations,
      hideButton,
      isCTAWidget,
      welcomeMessage,
      themeStartColor,
      themeEndColor,
      textColor,
      greeterImageUrl,
      googleAnalyticsEnabled,
      googleReviewsEnabled,
      googleRating,
      showGreeterMessage,
      hideBranding,
    } = data;

    let computedLocations = locations;
    let selectedShopToken = shopToken;

    /* 
      If the snippet has passed in hide button parameter then we need to use
      the token from the snippet and ignore all other locations that may be 
      returned from the widget-api config endpoint.
    */
    if (hideButton !== undefined) {
      // Logic for specialized
      setHideButton(hideButton);
      selectedShopToken = shopToken;
      computedLocations = [];
    } else {
      setHideButton(false);
      if (locations?.length === 1) {
        const location = locations[0];
        selectedShopToken = location.shopId || location.shop_id || location.id;
        computedLocations = [{ id: selectedShopToken }];
      }
    }

    if (computedLocations?.length === 0) {
      computedLocations = [{ id: selectedShopToken }];
    }

    setLocations(locations);

    setSelectedShopToken(selectedShopToken);
    setIsChatPopupVisible(!!welcomeMessage);
    setFormOpenOnLoad(formOpened);
    setDockLeft(dockLeft);
    setBottomOffset(bottomOffset || 0);
    setWelcomeMessage(welcomeMessage || '');
    setThemeStartColor(themeStartColor);
    setThemeEndColor(themeEndColor);
    setGreeterImageUrl(greeterImageUrl);
    setGoogleAnalyticsEnabled(googleAnalyticsEnabled);
    setGoogleRating(googleRating);
    setGoogleReviewsEnabled(googleReviewsEnabled);
    setShowGreeterMessage(showGreeterMessage);
    setHideBranding(hideBranding);
    setIsCTAWidget(isCTAWidget);

    setCssVar(
      '--ik-widget-bg-color-10',
      hexToRgba(themeStartColor || '#F2F9FF', 0.03)
    );
    setCssVar('--ik-widget-bg-color', themeStartColor);
    setCssVar('--ik-widget-bg-color-50', hexToRgba(themeStartColor, 0.5));
    setCssVar('--ik-widget-bottom-offset', `${bottomOffset || 0}px`);
    setCssVar('--ik-widget-text-color', textColor);
    setCssVar('--ik-widget-text-color-50', hexToRgba(textColor, 0.5));
    setCssVar('--ik-widget-left', dockLeft ? '30px' : null);
    setCssVar('--ik-widget-right', dockLeft ? null : '30px');
    formOpened && toggleWidget();
  };

  const toggleWidget = () => {
    setIsChatPopupVisible(false);
    setMinimized(!minimized);

    window.parent.postMessage(
      {
        type: 'MINIMIZE_WIDGET',
        value: !minimized,
      },
      '*'
    );

    widgetButtonRef?.current?.classList?.toggle('widget-min-hide');
    widgetCircleRef?.current?.classList?.toggle('widget-min-hide');
    widgetFormRef?.current?.classList?.toggle('widget-hide');
  };

  const onOpenWidget = (data) => {
    if (minimized) {
      toggleWidget();
    }

    setDefaultMessage(data.message_body);

    if (data.location_id) {
      setSelectedShopToken(data.location_id);
    }
  };

  React.useEffect(() => {
    window.parent.postMessage({ type: 'SEND_CONFIG' }, '*');
  }, []);

  return (
    <div
      style={{ height: '100vh', width: '100vw' }}
      onClick={() => {
        if (minimized) return;
        toggleWidget();
      }}
    >
      <ShimDataListener
        onInit={onInit}
        onOpenWidget={onOpenWidget}
        onCloseWidget={() => toggleWidget()}
      />
      <LocalEventHandler onInit={onInit} />
      <WidgetApp
        isChatPopupVisible={isChatPopupVisible}
        welcomeMessage={welcomeMessage}
        isCTAWidget={isCTAWidget}
        dockLeft={dockLeft}
        themeStartColor={themeStartColor}
        themeEndColor={themeEndColor}
        toggleWidget={toggleWidget}
        hideButton={hideButton}
        defaultMessage={defaultMessage}
        widgetFormRef={widgetFormRef}
        widgetButtonRef={widgetButtonRef}
        widgetCircleRef={widgetCircleRef}
        formOpened={formOpenOnLoad}
        selectedShopToken={selectedShopToken}
        bottomOffset={bottomOffset}
        locations={locations}
        greeterImageUrl={greeterImageUrl}
        googleAnalyticsEnabled={googleAnalyticsEnabled}
        googleRating={googleRating}
        googleReviewsEnabled={googleReviewsEnabled}
        showGreeterMessage={showGreeterMessage}
        hideBranding={hideBranding}
      />
    </div>
  );
};

export default DefaultWidgetManager;
