import React from 'react';
import { hexToRgba } from '../../lib/color';
import Copy from '../../copy/Copy';

const CtaWidgetButton = ({
  themeStartColor,
  themeEndColor,
  dockLeft,
  onClick,
  buttonRef,
}) => {
  const style = {
    display: 'flex',
    background: `linear-gradient(90deg, ${hexToRgba(
      themeStartColor
    )} 11%, ${hexToRgba(themeEndColor)} 100%)`,
    color: 'var(--ik-widget-text-color)',
    position: 'fixed',
    bottom: '30px',
    right: 'var(--ik-widget-right)',
    left: 'var(--ik-widget-left)',
  };

  const className = `widget-min`;

  return (
    <div style={style} ref={buttonRef} className={className} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        className="bi bi-chat-left-text-fill"
        viewBox="0 0 16 16"
      >
        <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4.414a1 1 0 0 0-.707.293L.854 15.146A.5.5 0 0 1 0 14.793V2zm3.5 1a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1h-9zm0 2.5a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1h-9zm0 2.5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5z" />
      </svg>
      <Copy>textUs</Copy>
    </div>
  );
};

export default CtaWidgetButton;
